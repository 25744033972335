import React from 'react'
import PropTypes from 'prop-types'
/* @jsx jsx */
import { jsx } from 'theme-ui'

const propTypes = {
  maxWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  minHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  margin: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  padding: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  bg: PropTypes.string,
  large: PropTypes.bool,
  smallplus: PropTypes.bool,
  fullscreen: PropTypes.bool,
  reference: PropTypes.bool,
  referencesmall: PropTypes.bool,
  children: PropTypes.node,
}

const defaultProps = {
  maxWidth: '960px',
  margin: '0 auto',
  padding: '3rem 5vw 1.5rem',
  bg: 'transparent',
  large: false,
  smallplus: false,
  fullscreen: false,
  reference: false,
  referencesmall: false,
  children: null,
}

const Grid = ({
  children,
  maxWidth,
  height,
  minHeight,
  margin,
  padding,
  bg,
  fullscreen,
  large,
  smallplus,
  reference,
  referencesmall,
  ...props
}) => {
  const heightProp = height && `height: height`
  const minHeightProp = minHeight && `gridAutoRows: ${minHeight}`

  return (
    <div
      sx={{
        display: `grid`,
        gridTemplateColumns: [
          `1fr`,
          reference || referencesmall ? `repeat(2, 1fr)` : `1fr`,
          reference ? `repeat(2, 1fr)` : referencesmall ? `repeat(3, 1fr)` : `repeat(2, 1fr)`,
          reference ? `repeat(3, 1fr)` : referencesmall ? `repeat(4, 1fr)` : `repeat(2, 1fr)`,
        ],
        gridAutoColumns: `1fr`,
        gridGap: [2, 3],
        maxWidth:
          (fullscreen && `container.fullscreen`) ||
          (large && `container.large`) ||
          (smallplus && `container.smallplus`) ||
          `container`,
        heightProp,
        minHeightProp,
        margin: margin,
        padding: (fullscreen && `0`) || padding,
        //pt: [3,4,5],
        bg: bg,
        zIndex: `-2`, // from Hero
        overflow: `hidden`,
      }}
      {...props}
    >
      <>{children}</>
    </div>
  )
}

Grid.propTypes = propTypes
Grid.defaultProps = defaultProps

export default Grid
