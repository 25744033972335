import React from 'react'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import Video from 'react-responsive-video'
/** @jsx jsx */
import { jsx } from 'theme-ui'
import Layout from '../components/Layout'
import SEO from '../components/Seo'
import Container from '../components/Container'
import Grid from '../components/Grid'
import Tile from '../components/Tile'

const propTypes = {
  data: PropTypes.object,
}

const About = ({ data }) => (
  <Layout line>
    <SEO title="Page two" />

    <Container smallplus>
      <p sx={{ mb: 2 }}>Vorgehensweise</p>
      <h1 sx={{ hyphens: `manual` }}>Mein Ansatz ist Ihr Vorteil</h1>
    </Container>

    <Grid large padding={0}>
      <Tile
        fluid={data.image01.childImageSharp.gatsbyImageData}
        sx={{ bg: `gray.5`, height: [`240px`, `320px`, `400px`, `400px`] }}
      />
      <Tile
        fluid={data.image02.childImageSharp.gatsbyImageData}
        sx={{ bg: `gray.5`, height: [`240px`, `320px`, `400px`, `400px`] }}
      />
    </Grid>

    <Container>
      <p>
        Ich berate und vertrete Sie - je nach Erfordernis diplomatisch oder kämpferisch - in allen
        Belangen des Strafrechts.
      </p>
      <p>Ihre Bedürfnisse als Mensch und Mandant stehen dabei immer im Mittelpunkt:</p>
      <ul>
        <li>
          Wir treffen niemals einsame Entscheidungen für Sie: Wir informieren Sie über Fakten und
          beraten gemeinsam mit Ihnen über die richtige Vorgehensweise.
        </li>
        <li>
          Sie erhalten umgehend einen Besprechungstermin - auch außerhalb der sonst üblichen Zeiten
          - wenn dies notwendig ist.
        </li>
        <li>
          Sie können uns bei Fragen jederzeit per Telefon oder E-Mail erreichen. Wir antworten
          zeitnah, falls Sie uns nicht persönlich erreichen.
        </li>
        <li>Wir informieren Sie unaufgefordert über neue Entwicklungen Ihres Falles.</li>
        <li>
          Wir werden Sie in keinen Prozess führen, bei dem die Gewinnchance in keinem Verhältnis zum
          Kostenrisiko steht.
        </li>
      </ul>
    </Container>
  </Layout>
)

About.propTypes = propTypes

export default About

export const query = graphql`
  {
    image01: file(relativePath: { eq: "rav/thanos-pal-qRR4zUYnoQ0-unsplash.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 900, placeholder: TRACED_SVG, layout: CONSTRAINED)
      }
    }
    image02: file(relativePath: { eq: "rav/nathz-guardia-dBnrs5XYTrs-unsplash.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 1200, placeholder: TRACED_SVG, layout: CONSTRAINED)
      }
    }
  }
`
